import styled from 'styled-components';

import { color } from '@/styles/variables';

const Container = styled.div<{ width?: string; height?: string }>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: ${props => (props.height ? props.height : 'auto')};
	width: ${props => (props.width ? props.width : '100%')};
`;
const Label = styled.div`
	font-size: 14px;
	span {
		color: ${color.primaryColor};
	}
`;

const WarningText = styled.p`
	color: ${color.primaryColor};
	font-size: 12px;
	line-height: 12px;
	margin: 0 0;
	min-height: 12px;
	padding: 0 0;
`;

interface FormItemContainerProps {
	required?: boolean;
	label?: string;
	/**
	 * 表单 item 具体内容
	 */
	children: React.ReactNode;
	warning?: string;
	width?: string;
	height?: string;
}

/**
 * 对 FormItem 容器的包装
 */
const FormItemContainer = ({
	required,
	label,
	children,
	warning,
	width,
	height
}: FormItemContainerProps) => {
	return (
		<Container width={width} height={height}>
			{(required || label) && (
				<Label>
					{required ? <span>*</span> : ''}
					{label}
				</Label>
			)}
			{children}
			{warning && <WarningText>{warning}</WarningText>}
		</Container>
	);
};

export default FormItemContainer;
