import { CSSProperties, ChangeEvent, HTMLInputTypeAttribute, useEffect, useState } from 'react';

import FormItemContainer from '../common/FormItemContainer';
import Input from '../common/Input';
import TextArea from '../common/TextArea';

import { isEmail } from '@/utils/validator';

interface FormItemComProps {
	required?: boolean;
	label?: string;
	value?: string;
	onChange: (value: string) => void;
	onCheck?: (hasError: boolean) => void;
	placeholder?: string;
	type?: HTMLInputTypeAttribute;
	/** 多行文本 */
	multiline?: boolean;
	/** 多行文本时-行数 */
	maxRows?: number;
	/** 最大长度 */
	maxLength?: number;
	min?: number;
	max?: number;
	style?: CSSProperties;
}

const FormItemCom = ({
	value,
	label,
	required,
	onChange,
	onCheck,
	placeholder,
	type,
	multiline,
	maxRows,
	maxLength,
	min,
	max,
	style
}: FormItemComProps) => {
	const [thisValue, setThisValue] = useState<string>('');

	const [warning, setWarning] = useState<string>('');

	const handleCheck = (v: string) => {
		if (required && v === '') {
			setWarning('输入不能为空');
			if (onCheck) {
				onCheck(true);
			}
			return;
		}
		if (type && type === 'email' && !isEmail(v)) {
			setWarning('请输入正确的邮箱');
			if (onCheck) {
				onCheck(true);
			}
			return;
		}
		if (maxLength && v.length > maxLength) {
			setWarning(`输入不能超过${maxLength}个字符`);
			if (onCheck) {
				onCheck(true);
			}
			return;
		}
		setWarning('');
		if (onCheck) {
			onCheck(false);
		}
	};
	useEffect(() => {
		if (value && value !== thisValue) {
			setThisValue(value || '');
			handleCheck(value || '');
		}
	}, [value]);
	const handleChange = (v: string) => {
		setThisValue(v);
		if (onChange) {
			onChange(v);
		}
	};

	return (
		<FormItemContainer
			required={required}
			label={label}
			warning={warning}
			width={style?.width as string}
		>
			{!multiline && (
				<Input
					value={thisValue}
					onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
					onBlur={(e: ChangeEvent<HTMLInputElement>) => handleCheck(e.target.value)}
					warning={warning}
					placeholder={placeholder}
					type={type}
					min={min}
					max={max}
					style={style}
				/>
			)}
			{multiline && (
				<TextArea
					value={thisValue}
					onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.value)}
					onBlur={(e: ChangeEvent<HTMLTextAreaElement>) => handleCheck(e.target.value)}
					placeholder={placeholder}
					warning={warning}
					maxRows={maxRows}
				/>
			)}
		</FormItemContainer>
	);
};

export default FormItemCom;
